import {
  APP_KEY
} from '../constants'
import Axios from './client'

export default {
  me(token: string) {
    return Axios.post('users/me', {
      app_uuid: APP_KEY,
      token
    })
  },
  authenticate(login: string, password: string) {
    return Axios.post('authenticate', {
      app_uuid: APP_KEY,
      email: login,
      password: password
    })
  },
  fetchFilters(token: string) {
    return Axios.post('users/me/filters', {
      app_uuid: APP_KEY,
      token
    })
  },
  friends(token: string) {
    return Axios.post('users/me/friends', {
      app_uuid: APP_KEY,
      token
    })
  },
  myWatchs(token: string) {
    return Axios.post('users/me/watch', {
      app_uuid: APP_KEY,
      token
    })
  },
  notifications(token: string) {
    return Axios.post('users/me/notifications', {
      app_uuid: APP_KEY,
      token
    })
  },
  notificationsRead(token: string) {
    return Axios.put('users/me/notifications', {
      app_uuid: APP_KEY,
      token
    })
  },
  resetPassword(login: string) {
    return Axios.post('users/reset-password', {
      app_uuid: APP_KEY,
      email: login
    })
  },
  revokeConnection(token: string) {
    return Axios.post('logout', {
      app_uuid: APP_KEY,
      token
    })
  },
  refreshToken(token: string) {
    return Axios.post('users/token/refresh', {
      app_uuid: APP_KEY,
      token
    })
  },
  delete(uuid: string, password: string, token: string) {
    return Axios.delete(`users/${uuid}`, {
      params: {
        app_uuid: APP_KEY,
        password,
        token
      }
    })
  },
  get(username: string) {
    return Axios.post('users/profile', {
      app_uuid: APP_KEY,
      username
    })
  },
  userWatch(userUuid: string) {
    return Axios.post(`users/${userUuid}/watch`, {
      app_uuid: APP_KEY
    })
  },
  search(term: string, token: string) {
    return Axios.post('users/search', {
      app_uuid: APP_KEY,
      term,
      token
    })
  }
}
