import {
  APP_KEY
} from '~/constants'
import { Season } from '~/types'
import Axios from "./client"

export default {
  create(anime_slug: string, season: Season, token: string) {
    return Axios.post(`anime/${anime_slug}/season`, {
      app_uuid: APP_KEY,
      ...season,
      token
    })
  },
  update(anime_slug: string, season: Season, token: string) {
    return Axios.put(`anime/${anime_slug}/season`, {
      app_uuid: APP_KEY,
      ...season,
      token
    })
  },
  delete(anime_slug: string, season_num: number, token: string) {
    return Axios.delete(`anime/${anime_slug}/season/${season_num}`, {
      params: {
        app_uuid: APP_KEY,
        token
      }
    })
  }
}
