import {
  APP_KEY
} from '~/constants'
import { Link } from '~/types'
import Axios from "./client"

export default {
  create(l: Link, token: string) {
    return Axios.post(`episode/${l.episode_id}/link/create`, {
      app_uuid: APP_KEY,
      token,
      ...l
    })
  },
  update(l: Link, token: string) {
    return Axios.put(`link/${l.id}`, {
      app_uuid: APP_KEY,
      token,
      ...l
    })
  },
  delete(id: number, token: string) {
    return Axios.delete(`link/${id}`, {
      params: {
        app_uuid: APP_KEY,
        token
      }
    })
  }
}
