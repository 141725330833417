import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _475304a0 = () => interopDefault(import('../src/pages/anime.vue' /* webpackChunkName: "pages/anime" */))
const _616974c0 = () => interopDefault(import('../src/pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _08e5add7 = () => interopDefault(import('../src/pages/news.vue' /* webpackChunkName: "pages/news" */))
const _0fbaa36b = () => interopDefault(import('../src/pages/reports.vue' /* webpackChunkName: "pages/reports" */))
const _6197cc36 = () => interopDefault(import('../src/pages/slider.vue' /* webpackChunkName: "pages/slider" */))
const _19c62834 = () => interopDefault(import('../src/pages/users.vue' /* webpackChunkName: "pages/users" */))
const _57476404 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/anime",
    component: _475304a0,
    name: "anime"
  }, {
    path: "/dashboard",
    component: _616974c0,
    name: "dashboard"
  }, {
    path: "/news",
    component: _08e5add7,
    name: "news"
  }, {
    path: "/reports",
    component: _0fbaa36b,
    name: "reports"
  }, {
    path: "/slider",
    component: _6197cc36,
    name: "slider"
  }, {
    path: "/users",
    component: _19c62834,
    name: "users"
  }, {
    path: "/",
    component: _57476404,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
