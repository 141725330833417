
import {
  APP_KEY
} from '~/constants'
import Axios from './client'

export default {
  fetch() {
    return Axios.post('producer', {
      app_uuid: APP_KEY
    })
  }
}