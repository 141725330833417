import {
  APP_KEY
} from '~/constants'
import Axios from './client'

export default {
  logs(token: string) {
    return Axios.post('link-hunter/logs', {
      app_uuid: APP_KEY,
      token
    })
  }
}