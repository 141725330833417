import {
  APP_KEY
} from '~/constants'
import Axios from './client'

export default {
  fetch() {
    return Axios.post('author', {
      app_uuid: APP_KEY
    })
  },
  create(name: string, token: string) {
    return Axios.post('author/create', {
      app_uuid: APP_KEY,
      name,
      token
    })
  }
}