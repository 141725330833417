import {
  APP_KEY
} from '~/constants'
import Axios from './client'

export default {
  fetchWaiting(token: string) {
    return Axios.post('comment/waiting/all', {
      app_uuid: APP_KEY,
      token
    })
  }
}