import { Middleware } from '@nuxt/types'
import { SnackbarType, User } from '~/types'

const notAuthenticatedMiddleware: Middleware = ({ store, redirect }: any) => {
  if(store.state.main.loggedIn) {
    return redirect('/dashboard')
  }
}

export default notAuthenticatedMiddleware

