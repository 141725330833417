import { SNACK_BAR_DELAY } from '~/constants'
import _ from 'lodash'
import {
  RootState,
  User,
  SnackbarType,
  SnackBarCtx,
  NSDialog,
  DialogImportanceCss
} from '../types'

export const state: () => RootState = () => ({
  loggedIn: false,
  token: null,
  user: null,
  snackbar:  {
    active: false,
    type: SnackbarType.ERROR,
    message: '',
    delay: SNACK_BAR_DELAY
  },
  isLoading: false,
  darkTheme: true,
  dialog: {
    active: false,
    style: DialogImportanceCss.LOW,
    title: '',
    message: '',
    confirm_label: 'Confirmer',
    cancel_label: 'Annuler',
    confirmCallback: null,
    cancelCallback: null,
    argscb: null
  }
})

export const mutations = {
  loginIn(state: RootState) {
    state.loggedIn = true
  },
  loginOut(state: RootState) {
    state.loggedIn = false
  },
  writeToken(state: RootState, t: string | null) {
    state.token = t
  },
  revokeToken(state: RootState, t: string) {
    state.token = t
  },
  updateSnackbarMessage(state: RootState, m: string) {
    state.snackbar.message = m
  },
  updateSnackbarState(state: RootState, b: boolean) {
      state.snackbar.active = b
  },
  updateUser(state: RootState, u: User) {
    if(state.user !== null) {
      state.user.avatar = u.avatar || state.user.avatar
      state.user.bio = u.bio || state.user.bio
      state.user.created_at = u.created_at || state.user.created_at
      state.user.groups = u.groups || state.user.groups
      state.user.roles = u.roles || state.user.roles
      state.user.updated_at = u.updated_at || state.user.updated_at
      state.user.user_uuid = u.user_uuid || state.user.user_uuid
      state.user.username = u.username || state.user.username
      state.user.settings = u.settings || state.user.settings
      state.user.pseudo_mal = u.pseudo_mal || state.user.pseudo_mal
    } else {
      state.user = u
    }
  },
  updateTheme(state: RootState, context: any) {
    if(state.user !== null) {
      const darkSetting: any = state.user.settings.find((s: any) => (s.id === 1))
      state.darkTheme = !!darkSetting?.pivot?.value
      // context.$vuetify.theme.dark = state.darkTheme
    }
  },
  updateSnackbarType(state: RootState, s: SnackbarType) {
    state.snackbar.type = s
  },
  unwriteUser(state: RootState) {
    state.user = null
  },
  updateLoading(state: RootState, b: boolean) {
    state.isLoading = b
  },
  updateDialogTitle(state: RootState, s: string) {
    state.dialog.title = s
  },
  updateDialogMessage(state: RootState, s: string) {
    state.dialog.message = s
  },
  updateDialogActive(state: RootState, b: boolean) {
    state.dialog.active = b
  },
  updateDialogCancelLabel(state: RootState, s: string) {
    state.dialog.cancel_label = s
  },
  updateDialogConfirmLabel(state: RootState, s: string) {
    state.dialog.confirm_label = s
  },
  updateDialogCancelCallback(state: RootState, c: Function) {
    state.dialog.cancelCallback = c
  },
  updateDialogConfirmCallback(state: RootState, c: Function) {
    state.dialog.confirmCallback = c
  },
  updateDialogStyle(state: RootState, css: DialogImportanceCss) {
    state.dialog.style = css
  },
  updateDialogArgumentsCb(state: RootState, args: any) {
    state.dialog.argscb = args
  },
}

export const actions = {
  snackbarMessage({commit}: any, m: string) {
    commit('updateSnackbarMessage', m)
  },
  changeSnackbarActive({commit}: any, b: boolean) {
    commit('updateSnackbarState', b)
  },
  changeSnackbarType({commit}: any, s: SnackbarType) {
    commit('updateSnackbarType', s)
  },
  login({commit}: any, u: User) {
    if(_.isObject(u)) {
      commit('updateUser', u)
      commit('loginIn')
      commit('updateTheme')
    }
  },
  logout({commit}: any) {
    commit('unwriteUser')
    commit('loginOut')
    commit('writeToken', null)
  },
  changeLoadingState({commit}: any, b: boolean) {
    commit('updateLoading', b)
  },
  changeSnackbarCtx({commit}: any, params: SnackBarCtx) {
    commit('updateSnackbarType', params.type)
    commit('updateSnackbarMessage', params.message)
    commit('updateSnackbarState', typeof params.active == "boolean" ? params.active : true)
  },
  changeDialogCtx({commit}: any, params: NSDialog) {
    commit('updateDialogActive', typeof params.active == "boolean" ? params.active : true)
    commit('updateDialogStyle', params.style || DialogImportanceCss.LOW)
    commit('updateDialogTitle', params.title)
    commit('updateDialogMessage', params.message)
    commit('updateDialogConfirmLabel', params.confirm_label || 'Confirmer')
    commit('updateDialogCancelLabel', params.cancel_label || 'Annuler')
    commit('updateDialogConfirmCallback', params.confirmCallback)
    commit('updateDialogCancelCallback', params.cancelCallback)
    commit('updateDialogArgumentsCb', params.argscb)
  }
}
