import {
  APP_KEY,
  APC_AXIOS_TIMEOUT
} from '~/constants'
import Axios from "./client"
import ohash from 'object-hash'

export default {
  all(token: string) {
    return Axios.post(`anime`, {
      app_uuid: APP_KEY,
      token
    })
  },
  addNote(slug: string, token: string, note: number) {
    return Axios.post(`me/anime/${slug}/note`, {
      app_uuid: APP_KEY,
      token,
      note
    })
  },
  fetch(slug: string, token: string) {
    return Axios.post(`anime/${slug}`, {
      app_uuid: APP_KEY,
      token
    })
  },
  fetchSearch(currentAnimeChunk: number, animePerChunk: number, searchArg: Object) {
    const signature = ohash(searchArg)
    const offset = (currentAnimeChunk * animePerChunk)
    return Axios.post(`anime/search/${offset}/full/${signature}`, searchArg)
  },
  fetchFilter(uuid: string) {
    return Axios.post(`anime/filter/${uuid}`, {
      app_uuid: APP_KEY
    })
  },
  suggestions(title: string) {
    var q = encodeURIComponent(title)

    return Axios.post(`anime/suggestion/${q}`, {
      app_uuid: APP_KEY
    })
  },
  comments(slug: string) {
    return Axios.post(`anime/${slug}/comments`, {
      app_uuid: APP_KEY
    })
  },
  topPopularity(last: number = 10) {
    return Axios.post(`anime/top/popularity/${last}`, {
      app_uuid: APP_KEY
    })
  },
  topNoted(last: number = 10) {
    return Axios.post(`anime/top/note/${last}`, {
      app_uuid: APP_KEY
    })
  },
  genders() {
    return Axios.post('genre', {
      app_uuid: APP_KEY
    })
  },
  producers() {
    return Axios.post('producer', {
      app_uuid: APP_KEY
    })
  },
  licensors() {
    return Axios.post('licensor', {
      app_uuid: APP_KEY
    })
  },
  authors() {
    return Axios.post('author', {
      app_uuid: APP_KEY
    })
  },
  planning(year: number, season: number) {
    return Axios.post(`planning/${year}/${season}`, {
      app_uuid: APP_KEY
    })
  },
  create(anime: FormData) {
    return Axios.post('anime/create', anime, { timeout: APC_AXIOS_TIMEOUT })
  },
  update(anime: FormData) {
    let slug = anime.get('slug')
    anime.delete('slug')
    anime.append('_method', 'PUT')
    return Axios.post(`anime/${slug}`, anime, { timeout: APC_AXIOS_TIMEOUT })
  },
  delete(anime_uuid: string, token: string) {
    return Axios.delete(`anime/${anime_uuid}`, {
      params: {
        app_uuid: APP_KEY,
        token
      }
    })
  },
  clearNgxCache(slug: string) {
    return Axios.post(`anime/${slug}`, {
      app_uuid: APP_KEY
    }, {
      headers: { 'X-ns-refresh-cache': 'true' }
    })
  }
}

