export interface User {
  avatar: string
  bio: string
  created_at: string
  groups: Array<Object>
  last_connected_from: string
  pseudo_mal: string
  reset_token: string
  roles: Array<Object>
  settings: Array<Object>
  updated_at: string
  user_uuid: string
  username: string
  watch: Object
}

export interface Season {
  id: number
  anime_id: number
  title: string
  publish_date: string
}

export interface Episode {
  id: number
  season: number
  number: string
  title: boolean
  publish_date: string
  type: string
  view: number
  display: boolean
}

export interface Link {
  id: number
  episode_id: number
  url: string
  streaming: boolean
  lang: string
}

export interface RootState {
  loggedIn: boolean
  token: string | null
  user: User | null
  snackbar: {
    active: boolean
    type: SnackbarType
    message: string
    delay: number
  }
  isLoading: boolean
  darkTheme: boolean,
  dialog: NSDialog
}

export interface NSDialog {
  active: boolean
  style: DialogImportanceCss
  title: string
  message: string
  confirm_label: string
  cancel_label: string,
  confirmCallback: Function | null,
  cancelCallback: Function | null,
  argscb: any
}

export enum DialogImportanceCss {
  CRITICAL = 'red--text',
  HIGH = 'orange--text',
  MODERATE = 'yellow--text',
  LOW = ''
}

export interface Setting {
  id: number
  default_value: any
  icon: string
  label: string
  list: string | null
  type: string
}

export enum SnackbarType {
  ERROR = "error",
  WARN = "warning",
  SUCCESS = "success",
  INFO = "info"
}

export interface SnackBarCtx {
  message: string,
  type: string,
  active: boolean
}
