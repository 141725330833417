import { userAPI } from '~/api'
import { SnackbarType, User } from '~/types'

export const state = () => ({
  counter: 0
})

export const mutations = {
  increment(state: any) {
    state.counter++
  }
}

export const actions = {
  async nuxtServerInit({ commit, dispatch }: any, { req, $cookies, $vuetify }: any) {
    const token = $cookies.get('token')

    if(token) {
      commit('main/writeToken', token, { root: true })
      try {
        const res = await userAPI.me(token)
        const user: User = res.data.me
        if(user) {
          await dispatch('main/login', user, { root: true })
          const darkSetting: any = user.settings.find((s: any) => (s.id === 1))
          const darkMode: boolean = !!darkSetting?.pivot?.value
          $vuetify.theme.dark = darkMode
        } else {
          $cookies.remove('token')
          dispatch('main/logout', { root: true })
          dispatch('main/changeSnackbarCtx', {
            type: SnackbarType.ERROR,
            message: 'Une erreur est survenue sur la récupération de session !',
            active: true
          }, { root: true })
        }
      } catch(error) {
        $cookies.remove('token')
        dispatch('main/logout', { root: true })
        dispatch('main/changeSnackbarCtx', {
          type: SnackbarType.ERROR,
          message: 'Votre session a expiré !',
          active: true
        }, { root: true })
      }
    }
  },
}
