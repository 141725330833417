import Vue from 'vue'
import { DialogImportanceCss, SnackbarType, User } from '~/types'
import {
  DAYS,
  MONTHS,
  COOKIE_RETENTION_PERIOD
} from '~/constants'

export default Vue.extend({
  methods: {
    setCookie(name: string, value: string) {
      this.$cookies.set(name, value, {
        path: '/',
        maxAge: COOKIE_RETENTION_PERIOD
      })
    },
    removeCookie(name: string) {
      this.$cookies.remove(name, {
        path: '/'
      })
    },
    async popSnackBar(msg: string, type = SnackbarType.ERROR): Promise<any> {
      try {
        return await this.$store.dispatch('main/changeSnackbarCtx', {
          message: msg,
          type
        })
      } catch(error) {
        console.error(error)
      }
    },
    async popDialog(title: string, message: string, style: DialogImportanceCss) {
      try {
        return await this.$store.dispatch('main/changeDialogCtx', {
          title,
          message,
          style,
          confirm_label: 'OK'
        })
      } catch(error) {
        console.error(error)
      }
    },
    prettyDate(dateStr: string | null = null, withTime: boolean = false): string {
      if(!dateStr) {
        return '-'
      }

      let d

      try {
        d = new Date(dateStr)
      } catch (error) {
        return ''
      }
      
      var strDate = (this.capitalize(DAYS[d.getDay()]) + ' ' + d.getDate() + ' ' + MONTHS[d.getMonth()] + ' ' + d.getFullYear())
      if(withTime) {
        strDate += ' à ' + this.strIntPadLeft(d.getHours().toString()) + 'h' + this.strIntPadLeft(d.getMinutes().toString())
      }

      return strDate
    },
    formatDate(date: string): string | null {
      if (!date) return null

      const d = new Date(date)
      return d.toLocaleDateString()
    },
    parseDate(date: string): string | null {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    strIntPadLeft(str: string, mask: string = '00'): string {
      if(isNaN(parseInt(str)) || isNaN(parseInt(mask))) {
          return str
      }
      str = '' + str   // Make sure it's a string
      return mask.substring(0, mask.length - str.length) + str
    },
    rejectToken(error: any) {
      console.log(`Token invalid / expired: ${error.response.status}`)
      this.$store.dispatch('main/logout', null)
      this.removeCookie('token')
      console.log('Token rejected')
    },
    capitalize(str: string, nonInvasive = false): string {
      if(!str) {
          return ''
      }
      return str.toLowerCase().charAt(0).toUpperCase() + (nonInvasive ? str.slice(1) : str.toLowerCase().slice(1))
    },
    capitalizeWords(str: string): String {
        if(!str) {
            return ''
        }
        return str.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
    },
    isObjectEmpty(obj: Object) {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object
    },
    isNumeric(n: number) {
      if (typeof n != "string") return false // we only process strings!  
      return !isNaN(n) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
             !isNaN(parseFloat(n)) // ...and ensure strings of whitespace fail
    }
  },
  computed: {
    user(): User {
      return this.$store.state.main.user
    },
    logged: {
      get(): boolean {
        return this.$store.state.main.loggedIn
      },
      set(b: boolean) {
        this.$store.commit('main/loginIn')
      }
    },
    loadingOverlay: {
      get() {
        return this.$store.state.main.isLoading
      },
      set(b) {
        this.$store.dispatch('main/changeLoadingState', b)
      }
    },
    token: {
      get() {
        return this.$store.state.main.token
      },
      set(t) {
        this.$store.commit('main/writeToken', t)
      }
    },
    isAdmin(): boolean  {
      if (!this.user) {
        return false
      }
      return (this.user.roles.findIndex((el: any) => {
        return el.isAdmin
      }) !== -1)
    },
    isUploader(): boolean  {
      if (!this.user) {
        return false
      }
      return (this.user.roles.findIndex((el: any)  => {
        return el.level === 4
      }) !== -1)
    },
    isModerator(): boolean {
      if (!this.user) {
        return false
      }
      return (this.user.roles.findIndex((el: any)  => {
        return el.level === 3
      }) !== -1)
    },
    isMember(): boolean  {
      if (!this.user) {
        return false
      }
      return (this.user.roles.findIndex((el: any)  => {
        return el.label === 'Member'
      }) !== -1)
    },
    hasAPCAccess(): boolean {
      return !!(this.isModerator || this.isAdmin || this.isUploader)
    },
  }
})
