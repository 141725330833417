import {
  APP_KEY
} from '~/constants'
import { Episode } from '~/types'
import Axios from "./client"

export default {
  fetch(anime_slug: string, token: string) {
    return Axios.post(`anime/${anime_slug}/episode/full`, {
      app_uuid: APP_KEY,
      token
    })
  },
  create(anime_slug: string, episode: Episode, token: string) {
    return Axios.post(`anime/${anime_slug}/episode/create`, {
      app_uuid: APP_KEY,
      ...episode,
      token
    })
  },
  update(episode: Episode, token: string) {
    return Axios.put(`episode/${episode.id}`, {
      app_uuid: APP_KEY,
      ...episode,
      token
    })
  },
  delete(id: number, token: string) {
    return Axios.delete(`episode/${id}`, {
      params: {
        app_uuid: APP_KEY,
        token
      }
    })
  }
}
