//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { APP_VERSION } from '~/constants'

export default Vue.extend({
  mounted() { // Le tricky truc 
    this.$vuetify.theme.dark = this.$store.state.main.darkTheme
  },
  methods: {
    logout() {
      this.$store.dispatch('main/logout')
      this.removeCookie('token')
      this.$router.push({ name: 'index' })
    },
    dialogCallback() {
      this.$store.commit('main/updateDialogActive', false)
      typeof this.dialogNS.confirmCallback === 'function' && this.dialogNS?.confirmCallback(this.dialogNS?.argscb)
    },
    dialogCancel() {
      this.$store.commit('main/updateDialogActive', false)
      typeof this.dialogNS.cancelCallback === 'function' && this.dialogNS.cancelCallback(this.dialogNS?.argscb)
    }
  },
  computed: {
    mini() {
        return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
    },
    snackbarTimeout() {
      return this.$store.state.main.snackbar.delay
    },
    snackbarText() {
      return this.$store.state.main.snackbar.message
    },
    snackState: {
      get() {
        return this.$store.state.main.snackbar.active
      },
      set(b) {
        this.$store.dispatch('main/changeSnackbarActive', b)
      }
    },
    snackBarType() {
      return this.$store.state.main.snackbar.type
    },
    viewName() {
      return this.capitalize(this.$route.name || '')
    },
    dialogNS() {
      return this.$store.state.main.dialog
    },
    app_version() {
      return APP_VERSION
    }
  },
  data: () => ({
    sidebarMenu: true,
    toggleMini: false,
    items_menu: [
      ['mdi-view-dashboard-outline', 'Tableau de bord', 'dashboard'],
      ['mdi-newspaper-variant-outline', 'Anime / Episode', 'anime'],
      ['mdi-newspaper-variant-multiple-outline', 'News', 'news'],
      ['mdi-movie-cog-outline', 'Slider', 'slider'],
      ['mdi-account-cog-outline', 'Users', 'users'],
      ['mdi-flag-variant-outline', 'Reports', 'reports']
    ],
  })
});
