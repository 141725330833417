export const TIME_NOTIONS = {   // In seconds!
  minute: 60,
  hour: (60 * 60),
  day: (60 * 60 * 24),
  week: (60 * 60 * 24 * 7),
  month: (60 * 60 * 24 * 30),
  year: (60 * 60 * 24 * 30 * 12)
}

export const DAYS = [
  'DIMANCHE',
  'LUNDI',
  'MARDI',
  'MERCREDI',
  'JEUDI',
  'VENDREDI',
  'SAMEDI'
]

export const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
]

export const THEMES_AVAILABLE = [
  'default',
  'dark',
  'dark-green'
]

export const SEASON_LABELS = [
  'spring',
  'summer',
  'fall',
  'winter'
]

export const APP_BASE_URL = 'https://neko-san.fr/'
export const MAIN_LOGO = 'https://resources.neko-san.fr/img/icon/logo-header.png'

export const APP_VERSION = '1.0.8'

export const APP_KEY = '23ad40e0-72a9-4aba-8caa-730e79992947'
export const CAPTCHA_INVISIBLE_KEY = '6Ld9eisUAAAAAO6oytgzJaSfwy8wtKKhcUhUIZ8N'

/* API NAMESPACE RELATION */

export const API_NEWS_NAMESPACE = 'App.Models.News'
export const API_ANIME_NAMESPACE = 'App.Models.Anime'
export const API_EPISODE_NAMESPACE = 'App.Models.Episode'
export const API_COMMENT_NAMESPACE = 'App.Models.Comment'

export const API_TYPES_NAMESPACE = [
  API_NEWS_NAMESPACE,
  API_ANIME_NAMESPACE,
  API_EPISODE_NAMESPACE,
  API_COMMENT_NAMESPACE
]

/* WebSocket (Socket.io) */
export const WS_SCHEM = 'https'
export const WS_URL = 'push.neko-san.fr'
export const WS_PORT = 443

export const PRIORITY_LOW = 2
export const PRIORITY_NORMAL = 1
export const PRIORITY_HIGH = 0

export const MAL_ANIME_LINKING = false

/* Temporal constants */
export const TOKEN_REFRESH_INTERVAL = 600000 // 10min
export const COOKIE_RETENTION_PERIOD = TIME_NOTIONS.week
export const NOTIFICATIONS_REFRESH_INTERVAL = (1000 * 240)
export const TIMEOUT_AXIOS = 15000
export const APC_AXIOS_TIMEOUT = 60000
export const SLIDER_TRANSITION_DELAY = 5000
export const SLIDER_TEMPORIZATION = 330
export const DELAY_DEBOUNCE_SEARCH_ANIME = 500
export const DELAY_DEBOUNCE_UPDATE_ANIME = 2000
export const DELAY_DEBOUNCE_CHECK_USERNAME = 500
export const CONNEXION_THROTTLE_DELAY = 1000
export const SNACK_BAR_DELAY = 3900
export const PROGRESS_DEADTIME = 1500

/* Limitations */
export const MIN_OUTS_PER_DAY = 8
export const MAX_OUTS_PER_DAY = 52
export const MAX_PIXELS_MOBILE = 740
export const MIN_PASS_LEN = 7

/* URL */
export const PROXY_NS_REQ_URL = 'https://proxy.neko-san.fr/?u='
export const RESOURCES_PATH_NS = 'https://resources.neko-san.fr/'
export const BASE_API_NS = 'https://api.neko-san.fr/'
export const IMG_ICON_EXTERNAL = RESOURCES_PATH_NS + 'img/icon/external/'
export const IMG_ANIME_DEFAULT_PATH_NS = RESOURCES_PATH_NS + 'default.jpg'
export const BCKG_ANIME_DEFAULT_NS = 'anime_default.full.jpg'
export const IMG_ANIMES_DEFAULT_NS = RESOURCES_PATH_NS + 'default.jpg'
export const IMG_NEWS_PATH_NS = RESOURCES_PATH_NS + 'img/news/'
export const IMG_ANIMES_PATH_NS = RESOURCES_PATH_NS + 'img/anime/'
export const IMG_USERS_PATH_NS = RESOURCES_PATH_NS + 'img/avatar/'
export const IMG_SLIDER_PATH_NS = RESOURCES_PATH_NS + 'img/slider/'
export const VERSION_LOG_URL_DIR = RESOURCES_PATH_NS + 'versions/'
export const DEFAULT_AVATAR_NAME = 'default.png'
export const DEFAULT_AVATAR_PATH = IMG_USERS_PATH_NS + DEFAULT_AVATAR_NAME

export const WATCH_STATUS = [
  {
      styleClass: 'watching',
      icon: 'play_arrow',
      tooltip: 'En cours',
      color: '#2ECC71',
      episode: true,
      action: 'watching'
  }, {
      styleClass: 'completed',
      icon: 'flag',
      tooltip: 'Terminé',
      color: '#5D9CEC',
      episode: true,
      action: 'completed'
  }, {
      styleClass: 'to-watch',
      icon: 'schedule',
      tooltip: 'À voir',
      color: '#AC92EC',
      episode: true,
      action: 'towatch'
  }, {
      styleClass: 'on-hold',
      icon: 'pause',
      tooltip: 'En pause',
      color: '#FFCE54',
      action: 'onhold'
  }, {
      styleClass: 'dropped',
      icon: 'stop',
      tooltip: 'Abandonné',
      color: '#D8334A',
      action: 'dropped'
  }
]

export const GROUPS_PROPS = [
  {
      color: '#E74C3C',
      icon: 'verified_user',
      customIcon: null,
      frenchLabel: 'Admin'
  },
  {
      color: '#5D9CEC',
      icon: 'headset_mic',
      customIcon: null,
      frenchLabel: 'Moderateur'
  },
  {
      color: '#AC92EC',
      icon: 'file_upload',
      customIcon: null,
      frenchLabel: 'Uploader'
  }
]

export const ROLES_PROPS = [
  false,
  false,
  {
      color: '#E74C3C',
      icon: 'verified_user',
      customIcon: null,
      frenchLabel: 'Admin'
  },
  {
      color: '#5D9CEC',
      icon: 'headset_mic',
      customIcon: null,
      frenchLabel: 'Modérateur'
  },
  {
      color: '#AC92EC',
      icon: 'file_upload',
      customIcon: null,
      frenchLabel: 'Uploadeur'
  },
  false,
  false,
  false,
  {
      color: '#FFCE54',
      icon: 'developer_mode',
      customIcon: null,
      frenchLabel: 'Membre'
  },
  {
      color: '#FFCE54',
      icon: 'developer_mode',
      customIcon: null,
      frenchLabel: 'Développeur'
  }
]

export const getGenericErrorMsg = (subject: String, code: Number) => {
  return {
      title: 'Meh :c',
      message: `Une erreur est survenue lors de ${subject} ! Code ${code}. Si le problème persiste, contactez un Admin!`
  }
}
